<template>
  <div class="min-h-screen h-full sm:mx-auto mx-2 max-w-4xl px-2 py-2 sm:px-6 lg:px-8">
    <div class="">
      <div class="sm:mx-auto mx-2 max-w-7xl px-2 py-2 md:pt-4 sm:px-6 lg:px-0">
      <!-- header -->
      <div class="border-b-2">
        <div @click="goback()" class="py-2 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor"
            class="cursor-pointer w-10 h-10 bg-indigo-500 hover:bg-indigo-300 rounded-full p-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
          </svg>
        </div>
        <h2 class="font-bold text-left py-2 text-xl md:text-3xl">
          Body Combat 8/20
        </h2>
      </div>
      <!-- classes -->
      <div class="pt-4 grid grid-cols-1 md:grid-cols-2 gap-1 justify-between">
        <div class="" v-for="(x, index) in  8" :key="index">
          <div class="mx-2 my-1 md:my-3 flex ">
            <div class="bg-zinc-800 h-16 md:h-16  rounded p-2 w-full">
                <div class="relative flex items-center  ">
                    <div class="pl-2 flex-1 " >
                        <p class="text-left font-bold text-sm">
                          Firstname {{index}}
                        </p>
                        <p class="text-left font-bold text-sm">
                          Lastname {{index}}
                        </p>
                    </div>
                    <div class=" flex-none">
                        <button v-show="index !== 0"  @click="book()" type="button" class="inline-flex items-center rounded-md border border-white  px-2 py-1 text-base font-bold text-whtie shadow-sm hover:bg-gray-50 hover:text-black focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:ring-offset-2">
                            Check-in
                        </button>
                        <button v-show="index === 0" @click="book()" type="button" class="bg-green-500 inline-flex items-center rounded-md    px-2 py-1 text-base font-bold text-whtie shadow-sm hover:bg-gray-50 hover:text-black focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:ring-offset-2">
                            Checked-in
                        </button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    </div>
    <div>
      <div class="text-center pt-4">
        <button type="button" class="font-bold mb-2 w-full inline-block px-6 py-2.5 bg-indigo-700 border-indigo-900 text-white font-medium text-xs rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
          <p class="font-bold text-lg">
            Check-in All
          </p>

        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goback () {
      this.$router.push('admin')
    }
  }
}
</script>