<!-- eslint-disable no-undef -->
<template>
  <div class="home ">
    
    <div class="bg-cream p-4 flex flex-col items-center justify-center min-h-screen">
      <h1 class="text-4xl mb-10 font-bold text-orange-500">Glossible</h1>
    <a href="https://s.lazada.co.th/l.WLw8" class="mb-4 bg-lazada text-white px-6 py-3 rounded-lg text-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 flex items-center justify-center">
        <img src="https://firebasestorage.googleapis.com/v0/b/kanatotech.appspot.com/o/%5BCITYPNG.COM%5DLazada%20Logo%20Icon%20Transparent%20Background%20-%201825x1442.png?alt=media&token=b34ef850-2360-48b9-96e5-878710357baa" alt="Lazada" class="h-6 w-6">
        <p class="ml-2">Lazada Shop</p>

    </a>
    <a href="https://www.tiktok.com/@glossibleco" class="mb-4 bg-tiktok text-white px-6 py-3 rounded-lg text-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 flex items-center justify-center">
        <img src="https://firebasestorage.googleapis.com/v0/b/kanatotech.appspot.com/o/602179070ad3230004b93c28.png?alt=media&token=fcb52fb7-632a-4e14-9a76-2b2d538e97b3" alt="TikTok" class="h-6 w-6">
        <p class="ml-2">TikTok Shop</p>
    </a>
    <a href="https://shop.line.me/@glossible.co" class="mb-4 bg-line text-white px-6 py-3 rounded-lg text-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 flex items-center justify-center">
        <img src="https://firebasestorage.googleapis.com/v0/b/kanatotech.appspot.com/o/LINE_APP_Android.png?alt=media&token=695a3118-e5eb-4318-9d99-9544053829bb" alt="Line" class="h-6 w-6">
        <p class="ml-2">Line My Shop</p>
    </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src
export default {
  mounted () {
    this.$fbq.init('936961910737630');
    // this.initializeFacebookPixel();
  },
  name: 'Home',
  data() {
    return {
      lat: 0,
      lng: 0,
      type: "",
      radius: "",
      places: []
    }
  },
  methods: {
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.lat = position.coords.latitude
          this.lng = position.coords.longitude
        }
      );
    },
    findCloseBuyButtonPressed() {
      const URL = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${
            this.lat
          },${this.lng}&type=${this.type}&radius=${this.radius *
            1000}&key=AIzaSyDzAWk1YHU4SZv0q4TC0P7_SL1SdBl9ZV8`;
      axios.get(URL).then(response => {
        this.places = response.data.results;
        this.addLocationsToGoogleMaps();
      }).catch(error => {
        console.log(error.message);
      });
    },
  },
  components: {
  },
  computed: {
    coordinates() {
      return `${this.lat}, ${this.lng}`;
    }
  }
}
</script>
<style>
body {
    --cream: #f5e7e0;
    --lazada: #000083;
    --tiktok: #000000;
    --line: #00B900;
}

.bg-cream {
    background-color: var(--cream);
}

.bg-lazada {
    background-color: var(--lazada);
}

.bg-tiktok {
    background-color: var(--tiktok);
}

.bg-line {
    background-color: var(--line);
}

.text-orange-500 {
    color: orange;
}

  
</style>