<template>
  <div class="">
    <div>
      <div class="relative">
        <img class="w-full" src="https://firebasestorage.googleapis.com/v0/b/boon-acbbb.appspot.com/o/young-woman-boxer-training-gym.jpeg?alt=media&token=82efa4ef-b023-4217-a573-589fabfda937" alt="" srcset="">
        <div @click="goback()" class="py-2 mb-4 absolute top-0">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor"
            class="cursor-pointer w-10 h-10 bg-indigo-500 hover:bg-indigo-300 rounded-full p-2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
          </svg>
        </div>
      </div>
      <div class="h-screen sm:mx-auto mx-2 max-w-4xl px-2 py-2 sm:px-6 lg:px-8">
      <!-- header -->
      <!-- classes -->
      <div>
        <div class="">
          <div class="   rounded p-2 md:p-0 w-full">
            <div class="relative  ">
              <div class=" text-left" >
                <p class=" text-white font-bold text-lg md:text-2xl">
                  Body Combat
                </p>
                <p class=" text-gray-300 font-bold text-md md:text-xl">
                  Saturday, 18 February, 10:00 to 11.00
                </p>
                <p class="font-bold text-white text-md md:text-xl">
                  Central Jangwattana
                </p>
                <div class="border-b-2 border-gray-200">
                  <p class="text-gray-300 pb-2 font-bold pt-4 text-lg md:text-xl">  
                    Teacher : Achan
                  </p>
                </div>
              </div>
              <div class="text-center pt-12">
                <button type="button" class="font-bold mb-2 w-full inline-block px-6 py-2.5 bg-indigo-700 border-indigo-900 text-white font-medium text-xs rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                  <p class="font-bold text-lg">
                    Book Now 
                  </p>

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    </div>
  
  </div>
</template>
<script>
export default {
	methods: {
    goback () {
      this.$router.push('/')
    }
  },
	data() {
		return {
			className: 'Yoga'
		}
	},
}
</script>
