<template>
  <div class="home min-h-screen h-full">
    <div>
      <div class="sm:mx-auto mx-2 max-w-7xl px-2 py-2 sm:px-6 lg:px-8">
      <!-- header -->
      <div class="relative flex justify-between h-16 ">
        
        <div class="pl-2 flex flex-nowrap">
          <img class="h-8" src="../assets/logo.png">
          <p class="font-bold pl-2">
            sugar
          </p>
          
        </div>
        <div class="pl-2">
          <button @click="goto()" type="button" class="px-4 inline-flex items-center rounded-md bg-blue-900 py-1 text-base font-bold text-whtie shadow-sm hover:bg-gray-50 hover:text-black focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:ring-offset-2">
              Schedule
          </button>
        </div>
      </div>
      <!-- location -->
      <div class="h-20">
        <div class="rounded bg-zinc-600 p-2">
          <div class="relative flex h-8 items-left  ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            </svg>
            <div class="pl-2">
              <p class="font-bold">
                Central Jangwattana
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- classes -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-1 justify-between">
        <div class="" v-for="(x, index) in  8" :key="index">
            <div class="mx-2 my-1 md:my-3 flex ">
            <div class="bg-zinc-800 h-26 md:h-34  rounded p-2 w-full">
                <div class="relative flex items-center  ">
                <div class="text-gray-200 flex-none">
                    <p class="text-sm md:text-lg font-bold">
                    09:00
                    </p>
                    <p class="text-xs pt-2 font-medium md:text-base">
                    10:00
                    </p>
                </div>
                <div class="pl-2 flex-1 " >
                    <p class="text-left font-bold text-sm md:text-lg">
                    Body Combat
                    </p>
                    <p class="font-bold text-left pt-4 text-xs md:text-lg">
                      Trainer {{index}}
                    </p>
                    <p class="font-bold text-left pt-2 text-md">
                      {{index}}/20
                    </p>
                </div>
                <div class=" flex-none">
                    <button @click="book()" type="button" class="inline-flex items-center rounded-md border border-white  px-2 py-1 text-base font-bold text-whtie shadow-sm hover:bg-gray-50 hover:text-black focus:outline-none focus:ring-2 focus:ring-indigo-200 focus:ring-offset-2">
                        Manage
                    </button>
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
  methods: {
    goto () {
      this.$router.push('class')
    },
    book () {
      this.$router.push('admin-confirm')
    }
  },
  components: {
  }
}
</script>
